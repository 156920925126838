
.yue{
    box-sizing: border-box;
    width: 3.3rem;
    height: 0.76rem;
    background-image: url('../../images/home/recharge_bg@2x.png');
    background-repeat: no-repeat;
    background-size: 3.3rem 0.76rem;
    display: flex;
    justify-content: space-between;
    color: #FCFCFD;
    align-items: center;
    padding: 0 0.2rem;
    font-size: 0.12rem;
}

.reg_bt{
    cursor: pointer;
    box-sizing: border-box;
    width: 1rem;
    height: 0.3rem;
    background-image: url('../../images/home/recharge_btn.png');
    background-repeat: no-repeat;
    background-size: 1rem 0.3rem;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    color: #40311F;
    align-items: center;
    font-size: 0.16rem;
    // padding: 0 0.2rem;
}

.meun-list{
    padding-top: 0.3rem;
    color: #FCFCFD;
    font-size: 0.16rem;
    width: 100%;
}
.meun-b{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;
    width: 100%;
    letter-spacing: 2px;
    line-height: 0.25rem;
}
.go-img{
    width: 0.15rem;
}
.meun-img{
    width: 0.25rem;
    margin-right: 0.1rem;
}

.bottom-box{
    box-sizing: border-box;
    position: relative;
    padding:  0.15rem 0.1rem 0 0.1rem;
    margin-top: 0.8rem ;
     height: 3.5rem;
     width: 3.5rem;
     border-radius: 0.1rem;
     background-color: #eac4c4;
     display: flex;
     justify-content: space-around;
     align-items: center;
     text-align: center;
     color: #fff;
}
@primary-color: #1DA57A;