.right-b{
    // position: fixed;
    // right: 20px;
    // bottom: 120px;
    // z-index: 99;
    text-align: center;
}
.close-b{
    // width: 32px;
    // height: 32px;
    // border: 1px solid #fb729e;
    // border-radius: 50%;
}
.top_nav_gom {
    
    background-color: white;
    border-radius: calc(12px);
    box-shadow: 0px 0px 6px #888888;
    padding: 5px;

}
.flex-cCenter {
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}
.flex-def {
    display: -webkit-box;
    /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box;
    /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox;
    /* 混合版本语法: IE 10 */
    display: -webkit-flex;
    /* 新版本语法: Chrome 21+ */
    display: flex;
    /* 新版本语法: Opera 12.1, Firefox 22+ */
}
.top_nav_gom>div{
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}
.top_nav_gom>div>img {
    width: 90%;
    border-radius: 0.05rem;
}

.top_nav_tag {
    margin-top: 10px;
    color: #fb729e;
    padding: 4px 7px;
    font-size: 12px;
    border-radius: calc(20px);
    border: 1px solid #fb729e;
    white-space: nowrap;
    position: relative;
}

.top_nav_gom .top_nav_tag_close {
    position: relative;
    right: -50px;
    top: -150px;
    color: #fb729e;
}

.adm-floating-bubble-button {
    position: fixed;
    top: var(--initial-position-top);
    bottom: var(--initial-position-bottom);
    left: var(--initial-position-left);
    right: var(--initial-position-right);
    box-sizing: border-box;
    width: var(--width);
    height: var(--height);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    transition: opacity ease 0.15s;
    -webkit-user-select: none;
    user-select: none;
    touch-action: none;
    background: var(--background);
    color: var(--adm-color-white);
    border-radius: var(--border-radius);
    z-index: var(--z-index);
}
@primary-color: #1DA57A;