.big-box{
    position: fixed;
    overflow: scroll;
    /* width: auto !important; */
    width: calc(100vw) !important;
    height: 100%;
    z-index: 98;
}

.box {
    bottom: 0;left: 0;
    position: fixed;
    z-index: 100;
}

.qr_bg{
    /* background-color: antiquewhite; */
    /* top: 0;
    left: 0;
    position: fixed; */
    /* height: 99.7%;
    width: 100%; */
    /* justify-content: center; */
    display: flex;
    align-items: end;
    /* margin-left: -375px; */
    z-index: 9;
    
}
.qr_bg img{
    /* position: absolute; */
    width: 251px;
    height: 315px;
}

.code-fixed{
    position: absolute;
    width: calc(100vw);
    height: 100%;
    background: #000;
    opacity: 0;
    z-index: 90;
}

.qr_code {
    position: absolute;
    margin-top: 127px;
    margin-left: 50px ;
    display: block;
    width: 296;
    height: 224;
    color: red;
    z-index: 100;
}