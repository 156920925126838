.max-box{
     position: fixed;
     width: 100%;
     height: 100%;
    //  top: 12vh;
     justify-content: center;
     align-items: center;
     display: flex;
     color: #fff;
     flex-direction: column;
    // width: calc(100vw) !important;
    // height: 100vh;
    // z-index: 9999;
    // overflow: auto; 


    /* background: #000; */
    // background:rgba(0,0,0,0.3);
    /* opacity: 0.6; */
    
}
.s-box-t{
    height: 65vh;
    width: 80%;
    border-radius: 0.1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    font-size: 0.14rem;
    text-align: left;
    // padding: 0rem 0  0.2rem 0;
    // margin: .1rem;
    background:rgba(0,0,0,0.8);
    // background-color: #000;
    
}

.t-box{
    box-sizing: border-box;
    color: #fff;
    padding-bottom: 0.1rem;
    margin: 0.1rem 0.15rem 0rem 0.15rem;
    justify-content: start;
    display: flex;
    border-bottom: #a8a7a7 1px solid;
    img{
        width: 0.3rem;
    }
}


.detail-ios{
    overflow-y: scroll;
    height: 56vh;
    box-sizing: border-box;
    padding: 0.15rem;
    flex-wrap:  wrap ;
img{width: 100%;border-radius: 0.1rem;margin-top: 0.07rem;}
}

@primary-color: #1DA57A;