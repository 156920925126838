@import '../../index.less';
.header-page {
    // background-color: @colorRed;
    padding: 0.1rem 0 ;
    height: 0.45rem;
    top: 0;
    width: 3.75rem;
    position: fixed;
    // justify-items: center;
    align-items: center;
    z-index: 999;
   background-color: #000D20;
}
.header-page-bg {
    // background-color: @colorRed;
    padding: 0.1rem 0 ;
    height: 0.45rem;
    top: 0;
    width: 3.75rem;
    position: fixed;
    // justify-items: center;
    align-items: center;
    z-index: 999;
    background-attachment: fixed;
    background-size: 100%;
   background-image: url('../..//images/home/body_bg.png');
   background-repeat: no-repeat;
   background-color: #000D20;
}

.bottom-line {
    border-bottom: 1px solid #efefeff3;
}

.notice-bar {
    --height:0.28rem;
    --font-size:0.13rem;
}

.tab-login{
    cursor: pointer;
    background: #3871fe;
    padding: 0 0.15rem;
    color: #fff;
    border-radius: 0.1rem;
    height: 0.3rem;
     display: flex;
      justify-content: center;
      align-items: center;

}

.adm-nav-bar-back {
    display: flex;
    align-items: center;
    margin-right: 0px;
    padding: 6px 0;
    cursor: pointer;
}
.adm-nav-bar {
    --height: 45px;
    --border-bottom: none;
    display: flex;
    align-items: center;
    height: var(--height);
    border-bottom: var(--border-bottom);
    padding: 0 0.16rem;
    white-space: nowrap;
  }
@primary-color: #1DA57A;