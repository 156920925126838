
  .bottom {
    width: 3.75rem;
    position: fixed;
    bottom: 0px;
    box-shadow: 3px 5px 10px 3px #e4e3e3;
    // border-top: solid 1px var(--adm-color-border);
    // background-image: linear-gradient(180deg, #e4e3e3, #000D20);
    background-color: #000D20;
    // background-image: url('../../images/home/home_mask@2x.png');
    padding: 0.05rem 0 ;
  }

  .margin-t{
    margin-top: 3px;
  }
@primary-color: #1DA57A;