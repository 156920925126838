.App {
  text-align: center;
}
.no-scroll {
  overflow: hidden;
}
.tit{
  margin-bottom: 0.1rem;
  font-weight: bold;
  font-size: 0.16rem;
  color: #fff;
}
.hh{
  margin: 0.1rem 0 ;
  font-size: 0.14rem;
  color: #Efff;
}
.font-c{
  line-height: 2;
  word-spacing: 2px;
  color: #9a9fa5;
}
body{
  background-color: #000D20;
  letter-spacing: 1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
