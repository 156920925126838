.is-fiexd{
    // style={{position:"fixed", bottom:"0.8rem", width:"100%"}}
    position: fixed;
    bottom: 0.8rem;
    width: 100%;
}
.bottom-flag{
    // color:"#9d9d9d", fontSize:"0.12rem", borderTop:"#4d4d4d 0px solid", paddingTop:"0.3rem", 
    color: #9d9d9d;
    cursor: pointer;
    font-size: 0.12rem;
    border-top: #4d4d4d 0px solid;
    padding-top: 0.3rem;
    @media screen and (min-width:1000px) {
        font-size: 14px;
    }
}
.copyri{
    // paddingLeft:"0.1rem", marginTop:"0.1rem", fontSize:"0.1rem", textAlign:"center"
    padding-left: 0.1rem;
    margin-top: 0.1rem;
    font-size: 0.1rem;
    text-align: center;
    @media screen and (min-width:1000px) {
        font-size: 12px;
        
    }
}
@primary-color: #1DA57A;