//定义全局颜色

@colorccc:#ccc;
@tabActiveColor:#418fe4;
@colorRed:#FCFCFD;

.colorRed{
    color: @colorRed;
}

.tabs1 input {
    padding: 0.05rem;
    box-sizing: border-box;
    --font-size: 0.14rem;
    border: 1px solid @colorccc;
    border-top:1.5px  solid @colorccc;
    // box-shadow: 0.08rem;
    // text-shadow: 4px;
    border-radius: 0.05rem;
    // outline: groove ;
}

.tabs1 input:focus{
    // border: 1px solid rgb(69, 123, 198);
    box-shadow:  0 0 0.08rem 1px rgb(69, 123, 198) ;
}
.box-center {
    // background-color: rgb(232, 232, 232);
    // font-family: 'MaterialIcons-Regular';
    box-sizing: border-box  ;
    // padding: 0.09375rem;
    float: left;
    width: 100%;
    margin-bottom: 0.8rem;
}


.text-desc {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;;
    font-family: Source Han Sans CN, Source Han Sans CN;
    box-sizing: border-box;
font-weight: 500;
font-size: 0.12rem;
color: #B1B5C3;
line-height: 0.16rem;
margin-top: 0.12rem;
}
@font-face{
  font-family: 'MaterialIcons-Regular';
  src: url('images/font/MaterialIcons-Regular.otf');
}
@font-face{
  font-family: 'CupertinoIcons';
  src: url('images/font/CupertinoIcons.ttf');
}
body{
  font-family: 'CupertinoIcons';
}