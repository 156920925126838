

.img-b{
    position:relative;
    
}
.item-img{
        width: 100%;
        // width: 3.433rem;
        // height: 1.9328rem;
        border-radius: 0.1rem;
        object-fit: cover;
    }
.des-b{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-family: Source Han Sans CN, Source Han Sans CN;
    color: #edeff3;
    // height: 0.25rem;
    overflow: hidden;
    color: hsl(0deg 0% 100% / 66%);

}

.triangle {
    position: absolute;
    // width: 0;
    // height: 0;
    // // background-color: aqua;
    // border-style: solid;
    // border-width: 0 100px 100px 0;
    // border-color: transparent lightblue transparent transparent;
    // transform: rotate(270deg);
    // opacity: 0.9;
    top: 0;
    left: 0;
    img{
        width: 1rem;
    }
}
.down-box{
    // style={{ background: "", color: "", width: "0.72rem", height: "0.32rem", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "0.14rem", fontWeight: "770", borderRadius: "0.16rem" }}
    background-color: rgb(20,63,59);
    cursor: pointer;
    color: #00cdba;
    width: 0.72rem;
    height: 0.32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.14rem;
    font-weight: 770;
    border-radius: 0.08rem;
    @media screen and (min-width:1000px) {
        height: 45px;
        width: 80px;
        font-size: 16px;
    }
}
@primary-color: #1DA57A;