.tabs input {
  padding: 0.05rem;
  box-sizing: border-box;
  --font-size: 0.14rem;
  border: 1px solid #ccc;
  border-top: 1.5px solid #ccc;
  border-radius: 0.05rem;
}
.tabs input:focus {
  box-shadow: 0 0 0.08rem 1px #457bc6;
}

