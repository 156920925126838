.rate-star {
    --star-size:0.1rem;
}

.good{
    text-align: left;
    box-sizing: border-box;
    cursor: pointer;
    width: 0.8rem;
    // height: 1.5rem;
    // background-color: rgb(245, 245, 245);;
    color: #B1B5C3;
    margin-bottom: 0.15rem;
    // margin: auto;
    vertical-align: middle;
    // float: left;
    // padding: 0.1rem 0.1rem ;
    // border-radius: 0.08rem;
    // background-color: #353945;
    // break-inside: avoid;

    // box-shadow: 5px 5px #353945;
}

.app-name{
    // width: 0.6rem;
    color: #FCFCFD;
    font-size: 0.12rem;
    font-weight: 550;
    margin-top: 0.03rem;
    line-height: 0.16rem;
    text-align: center;
    // white-space: nowrap;
    padding: 0 0.06rem;
    // overflow: hidden;
    text-overflow:clip;
    @media screen and (min-width:1000px) {
        font-size: 16px;
    }
}
@primary-color: #1DA57A;