.bar-nav {
    box-sizing: border-box;
    // background-color: rgb(245, 245, 245);;
    padding: 0.09375rem;
    width: 100%;
    border-radius: 0.08rem;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    margin: 0.05rem 0;
    .left{
        font-size: 0.18rem;
        @media screen and (min-width: 1000px) {
            font-size: 22px;
            
        }
        font-weight: bold;
        color: #FCFCFD;
        display: flex;
        justify-content: center;
        gap: 0.02rem;
    }
    .right{
        @media screen and (min-width: 1000px) {
            font-size: 20px;
            
        }
        cursor: pointer;
        margin-top: 0.08rem;
        font-size: 0.14rem;
        color: #fff;
        border-bottom: #fff 1px solid;
    }
}

.list-info{
    box-sizing: border-box;
    width: 100%;
    // column-count: 2;
    // margin-left: 0.05rem;
    // padding: 0 0.05rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}


@primary-color: #1DA57A;