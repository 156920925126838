@import "../../index.less";
.tabs-tab {
    // --active-line-color:@tabActiveColor;
    // --active-title-color:@tabActiveColor;
}
.tabs1 button {
    // background-color: @tabActiveColor;
}

.log-put{
    width: 100%;
    padding: 8px 15px;
    background-color: #2A2C38;
    height: 0.4rem;
    color: #B1B5C3;
    margin-top: 0.08rem;
}
.tabs1 input{
    border: none;
    outline: none;
}
@primary-color: #1DA57A;