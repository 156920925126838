
.bg-box {

    margin-bottom: -0.7rem;
    height: 85vh;
    background-image: url('../../images/img/123.png');
    // background-image: url('../../images/img/d-bg-b.png');
    background-repeat: no-repeat;
  // background-position: center ;
//   background-size: 100% 70%;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-position: bottom;
}
@primary-color: #1DA57A;