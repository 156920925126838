
.big-b{
    background: rgba(0, 0, 0, 0.55);
    opacity: 1;
    --z-index: var(--adm-mask-z-index, 1000);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index);
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: aqua;
    // padding: 0;
}

.moreLink{
    font-weight: bold;
    color: #ff61b6;
}
@primary-color: #1DA57A;