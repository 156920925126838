.notice-b {
    // style={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"0.5rem", width:"100%"}}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0.4rem;
    width: 100%;
    font-weight: 550;
    padding: 0px 0 ;
    box-sizing: border-box;
    // overflow: hidden;

}
.notice-img{
    width: 1rem;
    height: 0.5rem;
    border-radius: 4px;
    object-fit: cover;

}
.notice-tit{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-family: Source Han Sans CN, Source Han Sans CN;
    color: #edeff3;
    // height: 0.25rem;
    overflow: hidden;
    font-size: 0.14rem;
    @media screen and (min-width:1000px) {
        font-size: 16px;
    }
}
@primary-color: #1DA57A;