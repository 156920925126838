.base-bg-box{
    // background: #fff;
    position: absolute;
    width: 100%;
    // height: 100%;
    box-sizing: border-box;
    // border-radius: 0.08rem;
    // padding: 0.1rem;
    // margin-bottom: 0.1rem;
     background-attachment: fixed;
     background-size: cover;
     background-size: 100%;
    background-image: url('../../images/home/body_bg.png');
    background-repeat: no-repeat;
}
@primary-color: #1DA57A;