

.log-put-p{
    box-sizing: border-box;
    width: 100%;
    padding: 8px 15px;
    background-color: #2A2C38;
    height: 0.4rem;
    color: #B1B5C3;
    margin-top: 0.08rem;
    border-radius: 0.08rem;
}
.put input{
    border: none;
    outline: none;
}
@primary-color: #1DA57A;