.d-box{
    width: 100%;
    height: 100vh;
    // background-image: url('../../images/img/2.jpg');
    // background-image: url('../../images/img/33.png');
    // background-size: contain;
    background-size: 100% auto;
background-position: center;
background-repeat: no-repeat;
}
@primary-color: #1DA57A;